import React, { useEffect, useState } from 'react'
import * as styles from './category.module.scss';

import { Link, navigate } from 'gatsby';
import { useLocalPath } from '../../hooks';

import GridItems from './GridItems/GridItems';
import Checkbox from './Checkbox/Checkbox';

import Close from '../../static/close.svg';

const Category = ({
    slug,
    // tabs,
    filterGroups,
    products
}) => {
    const [filterArray, setFilterArray] = useState([])
    const [allProducts, setAllProducts] = useState(products)
    const checkActive = (tabSlug) => {
        if (tabSlug === slug)
            return styles.activeTab
        else
            return ''
    }

    const [openFilterPopup, setOpenFilterPopup] = useState(false)

    const filterProducts = () => {
        if (filterArray.length > 0) {
            const newProductArray = products.filter(({ filters }) => {
                if (filters?.find(({ id }) => filterArray.includes(id))) return true
                else return false
            })
            setAllProducts(newProductArray)
        } else {
            setAllProducts(products)
        }
    }

    useEffect(() => {
        filterProducts()
    }, [filterArray])

    const getSearchTerm = (searchTerm) => {
        if (searchTerm) {
            if (searchTerm.indexOf('remove') === -1) {
                setFilterArray([...filterArray, searchTerm])
            } else {
                const searchTermOut = searchTerm.replace('remove', '')
                const newSearch = filterArray.filter((term) => term !== searchTermOut)
                setFilterArray(newSearch)
            }
        }
    }

    const changeValue = (slug) => {
        navigate(slug)
    }
    return (
        <div className={`container container-xl`}>
            {/* <div className={styles.tabContainer}>
                <div className={styles.tabDesktop}>
                    {
                        tabs.map(({ title, target }, index) => (
                            <Link className={`${styles.link} ${checkActive(target?.slug)}`} to={useLocalPath(target?.slug)} key={index}>{title}</Link>
                        ))
                    }
                </div>
                <div className={styles.tabMobile}>
                    <label htmlFor="tab-links-mobile">Navigation</label>
                    <select name="" id="tab-links-mobile" onChange={(e) => changeValue(e.target.value)}>
                        {
                            tabs.map(({ title, target }, index) => (
                                <option value={useLocalPath(target?.slug)} className={`${styles.link}`} key={index}>{title}</option>
                            ))
                        }
                    </select>
                </div>
            </div> */}

            <div className={styles.container}>
                <div className={styles.filterDevice}>
                    <div className={styles.filterContainer}>
                        {
                            filterGroups.map(({ title, filters }, index) => {
                                const [viewMore, setViewMore] = useState(false)
                                return (
                                    <div className={styles.filterDiv} key={index}>
                                        <span className={styles.filterTitle}>{title}</span>
                                        <div className={styles.filterCheckboxContainer}>
                                            {
                                                filters.map(({ title, id }, index) => {
                                                    return viewMore
                                                        ? (
                                                            <Checkbox title={title} id={id} key={index} handleCheckboxSelection={getSearchTerm} />
                                                        )
                                                        : index < 3 && (
                                                            <Checkbox title={title} id={id} key={index} handleCheckboxSelection={getSearchTerm} />
                                                        )
                                                })
                                            }
                                        </div>
                                        {
                                            filters.length > 3
                                            && <p className={styles.viewMoreButton} onClick={() => setViewMore(!viewMore)}>{viewMore ? '閉じる' : "すべて見る"}</p>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.filterMob}>
                        <button className={styles.filterButton} onClick={() => setOpenFilterPopup(true)}>Refine Filter</button>
                    </div>
                </div>
                <div className={styles.gridContainer}>
                    <GridItems products={allProducts} />
                </div>
            </div>
            {
                openFilterPopup && <div className={styles.filterPopup}>
                    <div className={styles.filterContainer}>
                        <div className={styles.close} onClick={() => setOpenFilterPopup(false)}><Close /></div>
                        {
                            filterGroups.map(({ title, filters }, index) => {
                                return (
                                    <div className={styles.filterDiv} key={index}>
                                        <span className={styles.filterTitle}>{title}</span>
                                        <div className={styles.filterCheckboxContainer}>
                                            {
                                                filters.map(({ title, id }, index) => {
                                                    return (
                                                        <Checkbox title={title} id={id} key={index} handleCheckboxSelection={getSearchTerm} filters={filterArray} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <button className={styles.doneButton} onClick={() => setOpenFilterPopup(false)}>Done</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Category