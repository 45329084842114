import React, { useEffect, useRef, useState } from 'react'
import * as styles from './checkbox.module.scss';

const Checkbox = ({
    id,
    title,
    handleCheckboxSelection,
    filters = null
}) => {

    const checkBox = useRef()

    const [keyword, setKeyword] = useState()
    const searchTerm = (checked, value) => {
        if (checked) {
            setKeyword(value)
        } else {
            setKeyword(`${value}remove`)
        }
    }

    useEffect(() => {
        {
            handleCheckboxSelection(keyword)
        }
    }, [keyword])

    useEffect(() => {
        if (filters) {
            if (filters?.includes(id)) checkBox.current.checked = true
        }
    }, [])

    return (
        <div className={styles.filter}>
            <input
                type="checkbox"
                className={styles.filterCheckbox}
                id={`${title}-${id}`}
                onChange={(e) => {
                    searchTerm(e.target.checked, id)
                }}
                ref={checkBox}
            />
            <label htmlFor={`${title}-${id}`} className={styles.filterLabel}>{title}</label>
        </div>
    )
}

export default Checkbox