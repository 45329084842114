import React, { useEffect, useState } from 'react'
import * as styles from './grid-items.module.scss';

import ProductCard from '../../Shared/ProductCard/ProductCard';
import { useSiteDictionary } from '../../../hooks';
import ButtonPrimary from '../../Shared/ButtonPrimary/ButtonPrimary';

const GridItems = ({
    products
}) => {
    const [seeMore, setSeeMore] = useState(false)
    const {
        seeMoreProductsButton
    } = useSiteDictionary()

    const readMoreButton = () => {
        setSeeMore(true)
    }

    useEffect(() => {
        if (products.length <= 9) setSeeMore(true)
    }, [])

    return (
        <div>
            {
                products.length > 0 ? <div className={styles.griditems}>
                    {
                        products.map(({ thumbnailTitle, thumbnailImage, slug }, index) => {
                            return index < 9 ? (
                                <article key={index}>
                                    <ProductCard
                                        title={thumbnailTitle}
                                        image={thumbnailImage}
                                        slug={slug}
                                    />
                                </article>
                            ) : seeMore && (
                                <article key={index}>
                                    <ProductCard
                                        title={thumbnailTitle}
                                        image={thumbnailImage}
                                        slug={slug}
                                    />
                                </article>
                            )
                        })
                    }
                </div>
                    : <div>
                        <h2 className={styles.missingData}>No Data Available</h2>
                    </div>
            }
            {
                !seeMore && products.length > 9 && <div className={styles.buttonDiv}>
                    <ButtonPrimary
                        text={seeMoreProductsButton}
                        clickHandler={readMoreButton}
                        extraClass={styles.seeMoreButton}
                    />
                </div>
            }
        </div>
    )
}

export default GridItems