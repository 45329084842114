import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';
import SectionHeroSlider from '../components/sections/SectionHeroSlider/SectionHeroSlider';
import Category from '../components/Category/Category';

const ProductCategory = ({
    pageContext,
    location,
    data: {
        contentfulPageProductCategory: {
            slug,
            seoTitle,
            seoDescription,
            sections,
            // tabLinks,
            filterGroups,
            products
        }
    }
}) => {
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
            />
            <div className='content'>
                {
                    sections.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroComponent data={section} index={index} key={index} />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadCrumbs data={section} key={index} />
                                )
                            case "ContentfulSectionHeroSlider":
                                return (
                                    <SectionHeroSlider data={section} key={index} index={index} />
                                )
                        }
                    })
                }
                <Category
                    slug={slug}
                    // tabs={tabLinks}
                    filterGroups={filterGroups}
                    products={products || []}
                />
            </div>
        </div>
    )
}

export const ProductCategoryQuery = graphql`
    query ProductCategoryQuery( $id: String! ){
        contentfulPageProductCategory(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            sections {
                __typename
                ...componentHero
                ...sectionsBreadcrumbs
                ...sectionHeroSlider
            }
            #tabLinks {
            #    title
            #    target {
            #        ...linkTarget
            #    }
            #}
            filterGroups {
                title
                filters {
                    id
                    title
                    filterValue
                }
            }
            products {
                name
                thumbnailTitle
                slug
                thumbnailImage {
                    title
                    gatsbyImageData
                }
                filters {
                    id
                    title
                }
            }
        }
    }
`

export default ProductCategory